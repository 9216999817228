import * as React from "react";
import { Link } from "react-scroll";
import slugify from "slugify";

const slugOptions = {
  lower: true,
  strict: true,
  trim: true,
};

const FaqNav = ({ questions }) => (
  <div className="faq-nav">
    <ul // eslint-disable-line
      role="list"
    >
      {questions.group.map((group) => (
        <li key={group.fieldValue}>
          <Link
            activeClass="active"
            href={`#${slugify(group.nodes[0].category.title, slugOptions)}`}
            to={slugify(group.nodes[0].category.title, slugOptions)}
            spy
            smooth
            offset={-56}
            duration={500}
          >
            {group.nodes[0].category.title}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default FaqNav;
