import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import FaqNav from "../components/faq-nav";
import Questions from "../components/questions";

const FaqPage = ({ data }) => {
  const questions = data.questions;

  return (
    <Layout>
      <Seo title="FAQ" />
      <Hero title="FAQ" />
      <div className="faq">
        <div className="container">
          <FaqNav questions={questions} />
          <div className="faq-questions">
            {questions.group.map((group) => (
              <Questions
                questions={group.nodes}
                heading={group.nodes[0].category.title}
                key={group.fieldValue}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FaqPage;

export const query = graphql`
  query ($locale: String!) {
    questions: allContentfulQuestion(filter: { node_locale: { eq: $locale } }) {
      group(field: category___order) {
        fieldValue
        nodes {
          contentful_id
          title
          category {
            title
          }
          content {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                description
                gatsbyImageData(quality: 80)
              }
            }
          }
        }
      }
    }

    translations: contentfulTranslations(node_locale: { eq: $locale }) {
      translations {
        key
        value
      }
    }
  }
`;
